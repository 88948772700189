// required modules
import Service from '@/services/api/Service'
import GScheduleMeetingModel from '@/models/General/GScheduleMeeting'

/**
 * This class describes a GScheduleMeeting service.
 *
 * @class GScheduleMeeting (name)
 */
export default class GScheduleMeetingService extends Service {

  /**
   * 
   */
  static create(gScheduleMeeting) {
    return gScheduleMeeting.save()
  }

  /**
   * Define the GScheduleMeeting model.
   *
   * @return {Function} GScheduleMeeting resource.
   */
  static model() {
    return GScheduleMeetingModel
  }

  /**
   * 
   */
  static today() {
    let tNum = Date.now()
    let tDat = new Date(tNum)
    let tStr = `${ tDat.getDate() }-${ tDat.getMonth() }-${ tDat.getFullYear() } ${ tDat.getHours() }:${ tDat.getMinutes() }`
    return tStr
  }

  /**
   * 
   */
  static toCreate() {
    return this.shape({
      providerId: '',
      topic: '',
      description: '',
      startAt: this.today(),
      durationMinutes: '',
    })
  }
}