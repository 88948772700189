// required modules
import Model from '@/models/Model'

/**
 * This class describes a GScheduleMeeting model.
 *
 * @class GScheduleMeeting
 */
export default class GScheduleMeetingModel extends Model {

  /**
   * Define the GScheduleMeeting resource.
   *
   * @return {String} GScheduleMeeting endpoint name.
   */
  static resource() {
    return 'schedule_meeting'
  }
}